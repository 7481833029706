<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="iconSize"
    viewBox="0 0 36 24"
    fill="none"
    stroke="currentColor"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <circle
      class="animate-bounce ell1"
      cx="6"
      cy="18"
      r="1"
    /><circle
      class="animate-bounce ell2"
      cx="17"
      cy="18"
      r="1"
    /><circle
      class="animate-bounce ell3"
      cx="28"
      cy="18"
      r="1"
    />
  </svg>
</template>

<script lang="ts">
import useIcon from './useIcon'

export default useIcon('EllipsisLoadingIcon')
</script>

<style lang="scss">
#app .animate-bounce.ell1 {
  animation-duration: 1s;
}
#app .animate-bounce.ell2 {
  animation-duration: 1.2s;
}
#app .animate-bounce.ell3 {
  animation-duration: 1.4s;
}
</style>
